<template>
  <v-row class="fill-height" no-gutters>
    <v-col cols="12">
      <kiosk-list></kiosk-list>
    </v-col>
    <v-col cols="12">
      <v-card height="600" class="mt-4 mx-3 cardBorder" flat>
        <v-card-title class="d-flex align-center justify-center pt-3 font-weight-bold titleClass black--text ">Weekly
          Kiosk Utilization Snapshot
          ({{
            displayDate }})</v-card-title>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="4" align="center">
            <total-interaction-bar-chart></total-interaction-bar-chart>
          </v-col>
          <v-col cols="4" align="center">
            <PieChartWF></PieChartWF>
          </v-col>
          <v-col cols="4" align="center" class="py-2">
            <PieChartFF></PieChartFF>
          </v-col>
        </v-row>
        <v-card-title class="pa-0 pt-3 text-caption d-flex align-end justify-end">
          <span>Last Synced: {{ getCurrentDate }} | <a @click="syncData()">Sync Data</a></span>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex'
import KioskList from '../components/dashboard/admin/homeUI/kioskList.vue';
import TelepresenceNotes from '../components/telepresenceNotes.vue';
import TotalInteractionBarChart from '../components/graphs/Homepage/totalInteractionBarChart.vue';
import PieChartWF from '../components/graphs/Homepage/pieChartWF.vue';
import PieChartFF from '../components/graphs/Homepage/pieChartFF.vue';
import moment from "moment";

export default {
  name: 'home',
  data() {
    return {
      selectedKiosk: '',
      duration: "",
      timeoutId: null,
      date: new Date()
    }
  },
  components: {
    TelepresenceNotes,
    KioskList,
    TotalInteractionBarChart,
    PieChartWF,
    PieChartFF
  },
  computed: {
    ...mapGetters(["userProfile", "getDeviceSpec", "allRegisteredKiosks", "isSuperAdmin", "isAdmin", "isClientAdmin", "displayDate"]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getCurrentDate() {
      return moment(this.date).format('lll')
    },
  },
  methods: {
    autoLoadData() {
      setInterval(() => { this.syncData() }, 300000)
    },
    syncData() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.$store.dispatch('pullTotalInteractionData').then(() => {
        const timeoutSync = setTimeout(() => {
          this.date = new Date()
        }, 3000)
      })
    }
  },
  watch: {
    displayDate(newVal) {
      console.log("COming inside display date: ", newVal)
      this.duration = newVal
    }
  },
  mounted() {
    setTimeout(() => { this.autoLoadData() }, 4000)
  }
}
</script>
<style>
.cardBorder {
  border-width: 3px;
  border-style: solid;
  border-color: #E0E0E0 !important;
  /* border-radius: 5px !important; */
}

.titleClass {
  background-color: #E0E0E0;
}
</style>