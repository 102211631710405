<template>
    <!-- <h4>Bar Chart Coming</h4> -->
    <v-container fluid class="pt-0">
        <v-card flat height="450" class="px-3">
            <v-card-title class="d-flex align-center justify-center">Total Kiosk Interactions</v-card-title>
            <div id="chart-container-total"></div>
            <v-progress-circular indeterminate color="primary" size="25" v-if="isLoading"></v-progress-circular>
        </v-card>
    </v-container>
</template>
<script>
import * as d3 from 'd3';
import { mapGetters } from 'vuex';
export default {
    name: "totalInteractionBarChart",
    data() {
        return {
            isLoading: true,
            date: new Date()
        }
    },
    computed: {
        ...mapGetters(['getTotalData', 'getPieChartFFTotal', 'getPieChartWFTotal'])
    },
    watch: {
        getPieChartFFTotal(newVal) {
            console.log('New total of FF', newVal)
            this.createTotalBarChart()
        },
        getPieChartWFTotal(newVal) {
            console.log('New total of WF', newVal)
            this.createTotalBarChart()
        }
        // getTotalData(newVal) {
        //     this.createTotalBarChart()
        // }
    },
    methods: {
        createTotalBarChart() {
            d3.select('#chart-container-total').selectAll('*').remove();
            const width = 450
            const height = 400
            console.log('Total Data in graph page: ', this.getTotalData)
            const margin = { top: 25, right: 30, bottom: 65, left: 30 };
            this.getTotalData.sort((a, b) => a.id.localeCompare(b.id));
            const categories = this.getTotalData.map(d => d.category);

            const svg = d3.select('#chart-container-total')
                .append('svg')
                .attr('width', width)
                .attr('height', height);

            const xScale = d3.scaleBand()
                .domain(categories)
                .range([margin.left, width - margin.right])
                .padding(0.5);

            const yScale = d3.scaleLinear()
                .domain([0, d3.max(this.getTotalData, d => d.value)])
                .nice()
                .range([height - margin.bottom, margin.top]);

            // Append bars
            svg.selectAll('rect')
                .data(this.getTotalData)
                .enter()
                .append('rect')
                .attr('x', d => xScale(d.category))
                .attr('y', d => yScale(d.value))
                .attr('width', xScale.bandwidth())
                .attr('height', d => height - margin.bottom - yScale(d.value))
                .attr('fill', '#0d3b66');

            // Append labels to bars
            svg.selectAll('.bar-label')
                .data(this.getTotalData)
                .enter()
                .append('text')
                .text(d => (d.value !== 0 ? d.value : ''))
                .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
                .attr('y', d => yScale(d.value) - 10) // Adjust vertical position as needed
                .attr('text-anchor', 'middle')
                .attr('class', 'bar-label');

            // Append x-axis
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(xScale));

            // Rotate x-axis labels
            svg.selectAll('.x-axis text')
                .attr('transform', 'rotate(-30)')
                .style('text-anchor', 'end');

            // Append y-axis
            svg.append('g')
                .attr('class', 'y-axis')
                .attr('transform', `translate(${margin.left},0)`)
                .call(d3.axisLeft(yScale));

            // Append y-axis label
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('x', -height / 2)
                .attr('y', margin.left - 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('User Sessions');

            this.isLoading = false
        }
    },
    mounted() {
        console.log('COming here at mounted? ')
        const timeoutMounted = setTimeout(() => {
            this.createTotalBarChart()
            //this.date = new Date()
            this.isLoading = false
        }, 1500)

    }
}</script>