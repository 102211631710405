import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('kiosk-list')],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-4 mx-3 cardBorder",attrs:{"height":"600","flat":""}},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center pt-3 font-weight-bold titleClass black--text"},[_vm._v("Weekly Kiosk Utilization Snapshot ("+_vm._s(_vm.displayDate)+")")]),_c(VDivider),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"4","align":"center"}},[_c('total-interaction-bar-chart')],1),_c(VCol,{attrs:{"cols":"4","align":"center"}},[_c('PieChartWF')],1),_c(VCol,{staticClass:"py-2",attrs:{"cols":"4","align":"center"}},[_c('PieChartFF')],1)],1),_c(VCardTitle,{staticClass:"pa-0 pt-3 text-caption d-flex align-end justify-end"},[_c('span',[_vm._v("Last Synced: "+_vm._s(_vm.getCurrentDate)+" | "),_c('a',{on:{"click":function($event){return _vm.syncData()}}},[_vm._v("Sync Data")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }